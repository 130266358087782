const localPageData = {
  props: {
    localPageData: {
      type: Object,
      required: false
    }
  },
  computed: {
    pageData () {
      return this.localPageData || this.$xp.content.pageData
    }
  }
}

export default localPageData
