<template>
  <div class="js-entry o-entry c-text-entry">
    <div class="c-text-entry__content" v-html="entryData.Json.Text"></div>
  </div>
</template>

<script>
import { handleUrl } from '@/util/router-guards'
import entryMixin from '../../mixins/entry'

export default {
  mixins: [entryMixin],
  mounted () {
    [...this.$el.querySelector('.c-text-entry__content').getElementsByTagName('a')].forEach(element => {
      element.addEventListener('click', this.onLinkInTextClick)
    })
    // local development workaround, to render images in textentries

    const textImages = this.$el.querySelector('.c-text-entry__content').getElementsByTagName('img')
    if (textImages && textImages.length > 0) {
      textImages.forEach(element => {
        console.log('element.src', element.src)
        const baseUrl = this.$xp.settings.baseUrl
        // new regex
        if (element.src.match(/^http|https:\/\/localhost:[0-9]*\//)) {
          element.src = element.src.replace(/^(http|https):\/\/localhost:[0-9]*\//, baseUrl)
        } else if (element.src.match(/^(app:\/\/local\/)/)) {
          element.src = element.src.replace(/^(app:\/\/local\/)/, baseUrl)
        } else if (element.src.match(/^(file:\/\/\/android_asset\/www\/)/)) {
          element.src = element.src.replace(/^(file:\/\/\/android_asset\/www\/)/, baseUrl)
        }
      })
    }
  },
  methods: {
    onLinkInTextClick (e) {
      e.preventDefault()
      const targetAnchor = e.target.tagName === 'A' ? e.target : e.target.closest('a')
      if (targetAnchor.href) {
        const matches = targetAnchor.href.match(/PageID=(\d+)/)
        let url = ''
        if (matches && matches[1]) {
          url = `/page/${matches[1]}`
        } else {
          url = targetAnchor.href
        }
        handleUrl(url, this.$router)
      }
      this.$el.dispatchEvent(new Event(e.type, e))
    }
  }
}
</script>
